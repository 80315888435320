import React  from 'react';
import '../CSS/reset.css';
import HeaderComponent from './Header';

const Gallery = () => {
  
  return (
    <div >
        <HeaderComponent></HeaderComponent>
        <p>I am Gallery component</p>
    </div>
  );
};

export default Gallery;
